import React from 'react';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid,Text } from 'recharts';
import { withStyles } from '@material-ui/core/styles';
import deepPurple from '@material-ui/core/colors/purple';
import deepBlue from '@material-ui/core/colors/blue';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider, createMuiTheme }from '@material-ui/core/styles';

const data= [
  {'age': 44.0, 'year': 1792},
  {'age': 41.0, 'year': 1796},
  {'age': 44.0, 'year': 1800},
  {'age': 44.0, 'year': 1804},
  {'age': 45.0, 'year': 1808},
  {'age': 46.0, 'year': 1812},
  {'age': 43.0, 'year': 1816},
  {'age': 42.0, 'year': 1820},
  {'age': 42.0, 'year': 1824},
  {'age': 42.0, 'year': 1828},
  {'age': 44.0, 'year': 1832},
  {'age': 44.0, 'year': 1836},
  {'age': 43.0, 'year': 1840},
  {'age': 42.0, 'year': 1844},
  {'age': 42.0, 'year': 1848},
  {'age': 43.0, 'year': 1852},
  {'age': 44.0, 'year': 1856},
  {'age': 43.0, 'year': 1860},
  {'age': 46.0, 'year': 1864},
  {'age': 47.0, 'year': 1868},
  {'age': 47.0, 'year': 1872},
  {'age': 48.0, 'year': 1876},
  {'age': 49.0, 'year': 1880},
  {'age': 49.0, 'year': 1884},
  {'age': 48.0, 'year': 1888},
  {'age': 49.0, 'year': 1892},
  {'age': 49.0, 'year': 1896},
  {'age': 48.0, 'year': 1900},
  {'age': 49.0, 'year': 1904},
  {'age': 50.0, 'year': 1908},
  {'age': 51.0, 'year': 1912},
  {'age': 50.0, 'year': 1916},
  {'age': 51.0, 'year': 1920},
  {'age': 52.5, 'year': 1924},
  {'age': 55.0, 'year': 1928},
  {'age': 56.0, 'year': 1932},
  {'age': 53.0, 'year': 1936},
  {'age': 52.0, 'year': 1940},
  {'age': 52.0, 'year': 1944},
  {'age': 53.0, 'year': 1948},
  {'age': 53.5, 'year': 1952},
  {'age': 54.0, 'year': 1956},
  {'age': 53.0, 'year': 1960},
  {'age': 53.0, 'year': 1964},
  {'age': 52.0, 'year': 1968},
  {'age': 53.0, 'year': 1972},
  {'age': 51.0, 'year': 1976},
  {'age': 51.0, 'year': 1980},
  {'age': 50.0, 'year': 1984},
  {'age': 51.0, 'year': 1988},
  {'age': 53.0, 'year': 1992},
  {'age': 52.0, 'year': 1996},
  {'age': 54.0, 'year': 2000},
  {'age': 56.0, 'year': 2004},
  {'age': 58.0, 'year': 2008},
  {'age': 59.0, 'year': 2012},
  {'age': 59.0, 'year': 2016},
  {'age': 59.5, 'year': 2020},


];

const styles = theme => ({
  graphCard: {
    color: 'white',
    backgroundColor: '#10171e',//#191F35',
    //border: '2px solid ' + deepBlue[500],
  


  },
  chartTitle:{
    paddingBottom:10,



  },
  wrapper:{
    paddingLeft:20,
    paddingTop:10,
    
  },

  xAxisLabel:{
    textAlign:'center',
    marginTop:-10
  },
    yAxisLabel:{
    left:10,
    transform: [{ rotate: '90deg'}],
  }


});

const theme2 = createMuiTheme({
  palette: {

    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: deepPurple[300],
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
});



function AvgAgeLineChart(props) {
  
  const { classes } = props;
  return (

    
    <div className={classes.wrapper}>
    <MuiThemeProvider theme={theme2} >
    
    <Typography component="h3" variant="h6" align="left" color="primary" gutterBottom>
               Median Age of U.S. Congressional Seats
            </Typography>
    </MuiThemeProvider>
      
    <ResponsiveContainer className={classes.graphContainer}  width={'99%'} height={320}>
      <LineChart data={data} style={{color:'white'}}   margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <XAxis dataKey="year" interval={10}>
       
       
        </XAxis>
        <YAxis domain={[30, 70]} />
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <Tooltip />
      
  
        <Line type="monotone" dataKey="age" stroke={deepBlue[300]} dot={null}/>
   
   


        
      </LineChart>

    </ResponsiveContainer>

      </div>
  

  );
}
export default withStyles(styles)(AvgAgeLineChart);
