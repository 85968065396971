import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';

import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Header from '../sections/header.js';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider, createMuiTheme }from '@material-ui/core/styles';
import { Parallax, Background } from 'react-parallax';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';


import deepBlue from '@material-ui/core/colors/blue';
import deepPurple from '@material-ui/core/colors/purple';

const styles = theme => ({
  pitchSection:{
    backgroundColor: '#191F35',
    minHeight:800,
  },
  pitchContent:{
    padding: `${theme.spacing.unit * 4}px  ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      padding: `${theme.spacing.unit * 8}px  ${theme.spacing.unit * 4}px ${theme.spacing.unit * 6}px ${theme.spacing.unit * 4}px`,
    },
    
  },
  pitchContentItem:{
  
    backgroundColor: '#191F35',
    marginBottom: 30,
    padding: '28px 28px 28px 28px'

  },
  pitchHeroContentItem:{
    border: '2px solid ' + deepBlue[500],
    backgroundColor: '#191F35',
    marginBottom: 30,
    padding: '28px 28px 28px 28px'

  },
  highlight:{
    color: deepPurple[300],
    display: 'inline',
  },
  highlightAlt:{
    color: deepBlue[300],
    display: 'inline',
  },

  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },



});



const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light:  deepBlue[300],
      main: deepPurple[300],
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    typography: {
    useNextVariants: true,
  },
    // error: will use the default color
  },
});





function Values(props) {
  const { classes } = props;

  return (
    <div className={classes.pitchSection}>
        <div className={classNames(classes.layout,classes.pitchContent)}>


          <MuiThemeProvider theme={theme} >
          <Typography component="h1" variant="h3"align="center" color="primary" gutterBottom>
            Vision:
          </Typography>
          
          <div style={{ height: '30px' }} />

          <Typography component="h1" variant="h3"align="center" color="primary" gutterBottom>
            Balance Generational Representation
          </Typography>




         
            <Typography  variant="h6" align="left" color="primary">

            
                
                Young people are one of the least represented cohorts in American politics, and we are working to change that.
                Promote Ideas For the Future 
                We support progressive policies that help build a better future for young working class people.
                Increase Political Engagement of Young People
                We support young candidates that that talk about issues important to young people, to increase voter registration and participation from both Millennials and Generation Z.

           
            
            </Typography>
          
            <Typography variant="h6" align="left" color="primary">

              Media: media [AT] youngPAC.org
            
            </Typography>

  
            <Typography variant="h6" align="left" color="primary">

                <a href="https://twitter.com/theYoungPAC">@theYoungPAC</a>
            
            </Typography>

            
            
         
             

   
            

    

  

          

        </MuiThemeProvider>
        

        </div>
        </div>



  );
}

Values.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Values);