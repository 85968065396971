import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';


import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom'
import MediaCard from '../models/media_card.js';
import { MuiThemeProvider, createMuiTheme }from '@material-ui/core/styles';



const styles = theme => ({

  recReadSection:{
    backgroundColor: '#191F35',//'#10171e'
    minHeight:800,
  },
  recReadGrid:{
  

    display: 'flex',
    flexDirection: 'row',
    justify:'center',
    width:'auto',


  
  },




});

const featuredPosts = [
  {
    title: 'Why millennials are facing the scariest financial future of any generation since the Great Depression.',
    'date': 'Huffington Post',
    description:
      'What is different about us as individuals compared to previous generations is minor. What is different about the world around us is profound. Salaries have stagnated and entire sectors have cratered. At the same time, the cost of every prerequisite of a secure existence—education, housing and health care—has inflated into the stratosphere.',
    'link': 'https://highline.huffingtonpost.com/articles/en/poor-millennials',
    'image':'https://highline.huffingtonpost.com/articles/en/poor-millennials/media/images/Avo_twitter.png'
  },
  {
    title: 'Revealed: the 30-year economic betrayal dragging down Generation Y’s income',
    'date': 'The Guardian',
    description:
      'A combination of debt, joblessness, globalisation, demographics and rising house prices is depressing the incomes and prospects of millions of young people across the developed world, resulting in unprecedented inequality between generations.',
    'link': 'https://www.theguardian.com/world/2016/mar/07/revealed-30-year-economic-betrayal-dragging-down-generation-y-income',
    'image':'https://i.guim.co.uk/img/media/0c0792ca632c3c53b732b8f8411c003f925b392f/0_100_5333_3202/master/5333.jpg?width=1200&height=630&quality=85&auto=format&fit=crop&overlay-align=bottom%2Cleft&overlay-width=100p&overlay-base64=L2ltZy9zdGF0aWMvb3ZlcmxheXMvdGctZGVmYXVsdC5wbmc&s=738794b846cad89cfc4a88c5210a0c42'
  },
  // {
  //   title: 'Labor 2030: The Collision of Demographics, Automation and Inequality',
  //   'date': 'Bain & Company',
  //   description:
  //     'The combination of rising income and wealth inequality may raise questions about how resources are divided between baby boomer retirees and working-age millennials. Public pension and healthcare systems may be stretched to the breaking point in the coming decade.',
  //   'link': 'https://www.bain.com/insights/labor-2030-the-collision-of-demographics-automation-and-inequality/',
  //   'image':'https://www.bain.com/contentassets/fa89826544934e429f7b6441d6a5c542/labor-2030-the-collision-of-demographics-automation-and-inequality-1_1.jpg'
  // },
  {
    title: 'The coming conflict between millennials and boomers',
    'date': 'Axios',
    description:
      'Chiefly, it will pit millennials against boomers for jobs, and for differing government assistance: millennials will require job retraining and perhaps a basic income to compensate for low or no wages; and older Americans will demand the Social Security and health care that are bedrocks of current society.',
    'link': 'https://www.axios.com/an-emerging-conflict-between-millennials-an-1518030591-05825402-87a5-4ddf-86a3-8a39d38a6815.html',
    'image':'https://images.axios.com/8HpI_P2FdILEyMSkzP3rKw_cxPc=/0x186:2931x1835/1920x1080/2018/02/07/1518037957971.jpg'
  },
  // {
  //   title: 'At this rate, Gen X might never get to be president of the United States of America',
  //   'date': 'Quartz',
  //   description:
  //     'Chiefly, it will pit millennials against boomers for jobs, and for differing government assistance: millennials will require job retraining and perhaps a basic income to compensate for low or no wages; and older Americans will demand the Social Security and health care that are bedrocks of current society.',
  //   'link': 'https://qz.com/836658/donald-trumps-age-how-baby-boomer-generation-x-and-millennial-presidents-would-change-the-course-of-america/',
  //   'image':'https://cms.qz.com/wp-content/uploads/2016/11/chelsea-hillary-and-aidan-clinton.jpg?quality=75&amp;strip=all&amp;w=1400'
  // },
      {
    title: 'How the baby boomers — not millennials — screwed America',
    'date': 'Vox',
    description: '',
    'link': 'https://www.vox.com/2017/12/20/16772670/baby-boomers-millennials-congress-debt',
    'image':'https://cdn.vox-cdn.com/thumbor/LceBb7YkwRjqJ8Mbapf7fBg8n98=/0x0:594x311/fit-in/1200x630/cdn.vox-cdn.com/uploads/chorus_asset/file/9897181/538560203.jpg'
  },
    {
    title: 'Why Aren\'t Millennials Fed Up Paying Boomers\' Bills?',
    'date': 'Hartford Courant',
    description: '',
    'link': 'https://www.courant.com/opinion/op-ed/hc-op-wire-samuelson-millennials-boomers-social-security--1130-20151127-story.html',
    'image':'https://www.courant.com/resizer/bTPE9KpRjQYZhwqmfc0dNvRnTXc=/1200x0/arc-anglerfish-arc2-prod-tronc.s3.amazonaws.com/public/5WHGSCFXFRCT5FFYCT46FA63O4.jpg'
  },

  


];

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#ff4400',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
});

class RecomendedReading extends React.Component {
  constructor(props) {
        super(props);

    }



  render() {
    const { classes } = this.props;

    return (

          <div className={classes.recReadSection}>
            <div style={{ height: '60px' }} />
            <MuiThemeProvider theme={theme} >
            <Typography component="h1" variant="h3" align="center" color="primary" gutterBottom>
              Recommended Reading
            </Typography>
            </MuiThemeProvider>
            <div style={{ height: '100px' }} />
           

            <Grid container spacing={20} className={classes.recReadGrid} justify="center">

              {featuredPosts.map(post => (
                <Grid item className={classes.recReadGridItem}>
                <MediaCard 
                  title={post.title}
                  date={post.date}
                  description={post.description}
                  image={post.image}
                  link = {post.link}
                />
                </Grid>
          
              ))}
            </Grid>
             <div style={{ height: '60px' }} />
          </div>
  


    );
  }
}

RecomendedReading.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(RecomendedReading);