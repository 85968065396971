import React, { Component,PropTypes } from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme }from '@material-ui/core/styles';
import { Link } from 'react-router-dom'


const styles = theme => ({
  footer: {
    //paddingLeft: '20%',
    //paddingRight: theme.spacing.unit * 8,
    //borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit * 6}px`,
    backgroundColor: '#10171e',
  },

});
const theme = createMuiTheme({
  palette: {
    textPrimary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#8CA1B1',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
});

const footers = [
  {
    title: 'Contact',
    description: [{text:'contact [AT] youngPAC.org'}],
  },
  {
    title: 'Social',
    description: [{text:'@theYoungPAC', link:'https://twitter.com/theYoungPAC'}],


  },


];

const footers2 = [
'Paid for by youngPAC, www.youngPAC.org, and not authorized by any candidate or candidate\'s committee.',
'Contributions or gifts to youngPAC or endorsed candidates are not tax deductible.',
'Contributions are subject to the limitations and prohibitions of the Federal Election Campaign Act.',
'© youngPAC LLC 2019'
]

function Footer(props) {

      const { classes } = props;
      
                        
      return (
     
      
      
          

          <footer className={classNames(classes.footer, classes.layout)}>
            <Grid container spacing={32} justify="center" alignContent="center">
            <MuiThemeProvider theme={theme} >
              {footers.map(footer => (
                <Grid item xs key={footer.title}>
                  <Typography align="center" variant="h6" color="primary" gutterBottom>
                    {footer.title}
                  </Typography>
                  {footer.description.map(item => (
                    <Typography key={item.text} align="center" variant="subtitle1" color="primary">

                      <a href = {item.link}> {item.text} </a>


                    </Typography>
                  ))}
                </Grid>
              ))}
              </MuiThemeProvider>
            </Grid>
            <div style={{height:'50px'}} />
             <MuiThemeProvider theme={theme} >

            {footers2.map(footer => (

            <Grid container spacing={32} justify="center">
              <Grid item xs>
                <Typography align="center"  variant="caption" color="secondary">
                       {footer}
                </Typography>
              </Grid>
            </Grid>
            ))}
    
            </MuiThemeProvider>
          </footer>

            
           
          
        



     
      )
   
}

export default withStyles(styles)(Footer);
