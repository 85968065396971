import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';


import Home from './components/views/home.js';

import Contact from './components/views/contact.js';
import Form from './components/views/join.js'
import Mission from './components/views/mission.js'

import { BrowserRouter, Route } from 'react-router-dom';
import WebFont from 'webfontloader'

// WebFont.load({
//   google: {
//     families: ['Roboto:300,500,700']
//   }
// });

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'performAgeCheck': true,
  
    };

  }

  render() {
    return (

      <BrowserRouter basename={process.env.PUBLIC_URL}>
      <div>
          <Route exact={true} path= '/' component={Home} />
          <Route exact={true} path= '/contact' component={Contact} />
          <Route exact={true} path= '/join' component={Form} />
          <Route exact={true} path= '/mission' component={Mission} />
      
          
      </div>
    </BrowserRouter>

   
      
        
        

 
      
    );
  }
}

export default App;
