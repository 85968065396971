import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';

import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Header from '../sections/header.js';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider, createMuiTheme }from '@material-ui/core/styles';
import { Parallax, Background } from 'react-parallax';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';


import deepBlue from '@material-ui/core/colors/blue';
import deepPurple from '@material-ui/core/colors/purple';

const styles = theme => ({
  pitchSection:{
    backgroundColor: '#191F35',
    minHeight:800,
  },
  pitchContent:{
    padding: `${theme.spacing.unit * 4}px  ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      padding: `${theme.spacing.unit * 8}px  ${theme.spacing.unit * 4}px ${theme.spacing.unit * 6}px ${theme.spacing.unit * 4}px`,
    },
    
  },
  ach:{
    textTransform: 'uppercase',
  },
  pitchContentItem:{
  
    backgroundColor: '#191F35',
    marginBottom: 30,
    padding: '28px 28px 28px 28px'

  },
  pitchHeroContentItem:{
    border: '2px solid ' + deepBlue[500],
    backgroundColor: '#191F35',
    marginBottom: 30,
    padding: '28px 28px 28px 28px'

  },
  primarySpacerTop:{
    borderBottom: '2px solid ' + deepBlue[500],
    height:50

  },
  primarySpacerBottom:{

    height:50

  },
  highlight:{
    color: deepPurple[300],
    display: 'inline',
  },
  highlightAlt:{
    color: deepBlue[300],
    display: 'inline',
  },

  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },



});



const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light:  deepBlue[300],
      main: deepPurple[300],
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    typography: {
    useNextVariants: true,
  },
    // error: will use the default color
  },
});





function Vision(props) {
  const { classes } = props;

  return (
    <div className={classes.pitchSection}>
        <div className={classNames(classes.layout,classes.pitchContent)}>


          <MuiThemeProvider theme={theme} >
          <div style={{ height: '20px' }} />   
       
          <Typography component="h1" variant="h5" align="left" color="secondary" gutterBottom>
            OUR MISSION:
          </Typography>
          <Typography component="h3" variant="h4"align="left" color="primary" p >
            We elect young progressive candidates to office.
          </Typography>


          <div className={classes.primarySpacerTop} />
          <div className={classes.primarySpacerBottom}/>   

          <Typography component="h1" variant="h5" align="left" color="secondary" gutterBottom>
                OUR VISION:
          </Typography>
          
          

          <Typography className={classes.ach}  variant="h5" align="left" color="primary" gutterBottom>
            Balance Generational Representation 
          </Typography>
         
          <Typography  variant="h6" align="left" color="primary" gutterBottom>      
              Young people are one of the least represented cohorts in American politics, and we are working to change that.
          </Typography>


          <div style={{ height: '60px' }} />


          <Typography className={classes.ach} variant="h5" align="left" color="primary" gutterBottom>
            Promote Ideas For the Future 
          </Typography>
          <Typography  variant="h6" align="left" color="primary" gutterBottom>      
              
             We support <a target='_blank' href="https://www.justicedemocrats.com/issues/"> progressive policies</a> that help build a better future for young working class people.

          </Typography>


          <div style={{ height: '60px' }} />
          

          <Typography  className={classes.ach} variant="h5" align="left" color="primary" gutterBottom>
            Increase Political Engagement of Young People
          </Typography>
          <Typography  variant="h6" align="left" color="primary" gutterBottom>      
              
             We support young candidates that that talk about issues important to young people, to increase voter registration and participation from both Millennials and Generation Z.

          </Typography>


          <div className={classes.primarySpacerTop} />
          <div className={classes.primarySpacerBottom}/>   
             

          <Typography component="h1" variant="h5" align="left" color="secondary" gutterBottom>
                OUR VALUES:
          </Typography>
          
  


          <Typography className={classes.ach}  variant="h5" align="left" color="primary" gutterBottom>
            Champion Diversity
          </Typography>
         
          <Typography  variant="h6" align="left" color="primary" gutterBottom>      
              Our government should accurately represent its people. Having a diverse set of backgrounds, viewpoints, and ideals makes us stronger and results in policy proposals good for the majority.
          </Typography>


          <div style={{ height: '60px' }} />


          <Typography className={classes.ach}  variant="h5" align="left" color="primary" gutterBottom>
            Challenge the status quo
          </Typography>
          <Typography  variant="h6" align="left" color="primary" gutterBottom>      
              
             Yesterday’s decisions were made in the context of yesterday’s technology, population, social and environmental climate. Today’s decisions should reflect the context young people face now and in the future.  We should constantly be evaluating what needs to change.

          </Typography>


          <div style={{ height: '60px' }} />
          

          <Typography className={classes.ach}  variant="h5" align="left" color="primary" gutterBottom>
            Provoke and Instigate
          </Typography>
          <Typography  variant="h6" align="left" color="primary" gutterBottom>      
              
             Convert “change wishers” to “change drivers,” and provoke people into action.

          </Typography>

          <div style={{ height: '60px' }} />
          

          <Typography className={classes.ach}  variant="h5" align="left" color="primary" gutterBottom>
            Remember why change matters
          </Typography>
          <Typography  variant="h6" align="left" color="primary" gutterBottom>      
              
             Understanding the struggles of working class citizens is key to developing candidates and winning campaign strategies. 

          </Typography>


          <div style={{ height: '60px' }} />

            
                
          

        </MuiThemeProvider>
        

        </div>
        </div>



  );
}

Vision.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Vision);