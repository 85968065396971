import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';

import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Header from '../sections/header.js';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider, createMuiTheme }from '@material-ui/core/styles';
import { Parallax, Background } from 'react-parallax';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import SimpleLineChart from '../models/simpleLineChart.js'
import AvgAgeLineChart from '../models/avgAgeLineChart.js'
import IncomeBarChart from '../models/incomeBarChart.js'

import deepBlue from '@material-ui/core/colors/blue';
import deepPurple from '@material-ui/core/colors/purple';
import toRenderProps from 'recompose/toRenderProps';
import withWidth from '@material-ui/core/withWidth';
import Hidden from '@material-ui/core/Hidden';


const styles = theme => ({
  problemSection:{
    backgroundColor: '#10171e',//'#191F35',
    
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      //display:'none',
      minHeight:800,
    },
  },
  problemContent:{
    padding: `${theme.spacing.unit * 4}px  ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      padding: `${theme.spacing.unit * 8}px  ${theme.spacing.unit * 4}px ${theme.spacing.unit * 6}px ${theme.spacing.unit * 4}px`,
    },
    
  },
  problemGridAlt:{

    display: 'flex',



    

 


  },
  problemGrid:{

    display: 'flex',
    //border: '2px solid ' +deepBlue[500],
    flexDirection: 'row',
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      flexDirection: 'row',
      //border: '2px solid ' +deepBlue[500],
    },
    [theme.breakpoints.down(400 + theme.spacing.unit * 3 * 2)]: {
      //display:'none',
      flexDirection: 'column',
    },

    

 


  },
  problemGridItem:{



  },
  problemGridItemAlt:{
    [theme.breakpoints.down(400 + theme.spacing.unit * 3 * 2)]: {
     // display:'none',
    },



  },

  problemCard:{
    height: 400,
    width:'auto',

    //padding: `20%  ${theme.spacing.unit * 2}px 20% ${theme.spacing.unit * 2}px`,
    //marginLeft:-100,
    //marginRight:-100,
    background: '#10171e',

    [theme.breakpoints.up(800 + theme.spacing.unit * 3 * 2)]: {
      height:'auto',

      padding: `${theme.spacing.unit * 1}px   ${theme.spacing.unit * 1}px ${theme.spacing.unit * 1}px  ${theme.spacing.unit * 1}px`,
     
    },
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      height:400,
      width:400,
      padding: `20%  ${theme.spacing.unit * 4}px 20% ${theme.spacing.unit * 4}px`,
    },

  },
  problemCardAlt:{
    height:400,
    width:'auto',
  
    background: '#10171e',
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      height:400,
      width:'auto',
    },
    [theme.breakpoints.down(400 + theme.spacing.unit * 3 * 2)]: {
     //display:'none',
    },

  },

  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      //width: 1600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },


});



const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: deepPurple[300],
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
});

const featuredPosts = [
 {
    pre:' In 2013, for the ',
    main: 'first time ever',
    title: 'average U.S. under-30s had less income than those aged 65-79.',

  },
  {
    pre:'Millennials have only',
    main:'34%',
    title: 'of the congressional representation the boomers had at the same age.',
    source: "Washington Post"

  },
  {
    main:'Lack of Money',
    title: '#1 most cited reason for not running',
    source: "Washington Post"

  },

 


];



function Problem(props) {
  const { classes } = props;
  const WithWidth = toRenderProps(withWidth());

  console.log(theme.breakpoints.values);

  return (
    <div className={classes.problemSection}>
        <div className={classNames(classes.layout,classes.problemContent)}>


      
          <MuiThemeProvider theme={theme} >
          <Typography  variant="h4" align="center" color="primary" gutterBottom>
           Did you know young people face <b>unprecedented</b> generational inequality?
          </Typography>
          
          <div style={{ height: '60px' }} />

 



                
        <Hidden smUp>
          <div style={{ height: '50px' }} />

         <Typography  variant="h6" align="center" color="primary" gutterBottom>
           [ <i>There are lots of cool graphs here on the desktop / ipad version, but they don't display nicely on mobile :( Turn your phone sideways, to landscape mode, to see them!</i>]
          </Typography>
           <div style={{ height: '160px' }} />
        </Hidden>
          
          
          
          <Hidden xsDown>
       
          <div style={{ height: '150px' }} />
            <Grid container spacing={40} className={classes.problemGrid} justify="space-evenly" alignContent="center" wrap='wrap' >
              <Grid item   className={classes.problemGridItem} xs={4} s={4}  >
                
                <Card className={classes.problemCard} >
             
                  <Typography component="h2" variant="h5"  align="center"  color="primary" >
                    {featuredPosts[0].pre}
                  </Typography>

                  <Typography component="h1" variant="h2"  align="center" color="secondary" >
                    {featuredPosts[0].main}
                  </Typography>
                  <Typography component="h2" variant="h5"  align="center" color="primary" >
                    {featuredPosts[0].title}
                  </Typography>
                  <Typography component="h1" variant="caption"  align="right" color="secondary" gutterBottom>
                    <a href="https://www.theguardian.com/world/2016/mar/07/revealed-30-year-economic-betrayal-dragging-down-generation-y-income"> source 
                    </a>
                  </Typography>
              
                </Card>
              </Grid>

  
              <Grid item className={classes.problemGridItem}  xs={6} s={6}  >
                <Card className={classes.problemCardAlt} >
                  <IncomeBarChart width={400}/>
                 
                </Card>
              </Grid>


      
            </Grid>
     

        <div style={{ height: '200px' }} />

        <Typography component="h2" variant="h4"  align="center" color="primary"  >
                    The problem: <a href="https://www.vox.com/2017/12/20/16772670/baby-boomers-millennials-congress-debt">old boomers enacting self-serving policies </a>
                  </Typography>

           <div style={{ height: '150px' }} />
        
          <Grid container spacing={40} className={classes.problemGrid} justify="space-evenly" alignContent="center" >


              <Grid item className={classes.problemGridItemAlt} xs={6} s={6} >
                <Card className={classes.problemCardAlt} >
                  <AvgAgeLineChart />
                 
                </Card>
              </Grid>
      
       
              <Grid item  className={classes.problemGridItem} xs={4} s={4} >
                <Card className={classes.problemCard} >
                  
                  <Typography component="h2" variant="h5"  align="center" color="primary" >
                  In 2018 the median age of U.S. Congress Representatives was at an
               
                  </Typography>

                  <Typography component="h1" variant="h2"  align="center" color="secondary" gutterBottom>
                    all-time high.
                  </Typography>
                  <Typography component="h1" variant="caption"  align="right" color="secondary" gutterBottom>
                    <a href="https://github.com/eo777/congress_age_analysis"> source 
                    </a>
                  </Typography>


                </Card>
              </Grid>
          </Grid>

          <div style={{ height: '200px' }} />

        <Typography component="h2" variant="h4"  align="center" color="primary"  >
                    We don't have enough representatives looking out for our interests.
         </Typography>
        



           <div style={{ height: '150px' }} />
          
          <Grid container spacing={40} className={classes.problemGrid} justify="space-evenly" alignContent="center">



              <Grid item className={classes.problemGridItem} xs={4} s={4} >
                <Card className={classes.problemCard} >
                  <Typography component="h2" variant="h5"  align="center" color="primary" >
                    {featuredPosts[1].pre}
                  </Typography>

                  <Typography component="h2" variant="h1"  align="center" color="secondary" >
                    {featuredPosts[1].main}
                  </Typography>
                  <Typography component="h2" variant="h5"  align="center" color="primary" >
                    {featuredPosts[1].title}
                  </Typography>

                  <Typography component="h1" variant="caption"  align="right" color="secondary" gutterBottom>
                    <a href="https://github.com/eo777/congress_age_analysis"> source 
                    </a>
                  </Typography>
                </Card>
              </Grid>

              <Grid item className={classes.problemGridItemAlt} xs={6} s={6}> 
                <Card className={classes.problemCardAlt} >
                  <SimpleLineChart width={'99%'}/>
                 
                </Card>
              </Grid>

          </Grid>


           <div style={{ height: '200px' }} />

 

          <Typography component="h2" variant="h5"  align="center" color="primary" >
                    {featuredPosts[2].pre}
                  </Typography>

                  <Typography component="h1" variant="h2"  align="center" color="secondary" >
                    {featuredPosts[2].main}
                  </Typography>
                  <Typography component="h2" variant="h5"  align="center" color="primary" >
                    {featuredPosts[2].title}
                  </Typography>
                  <Typography component="h1" variant="caption"  align="center" color="secondary" gutterBottom>
                    <a href="https://news.rutgers.edu/sites/medrel/files/news-clips/Why%20Millennials%20Don%E2%80%99t%20Want%20to%20Run%20for%20Office%20-%20The%20Chronicle%20of%20Higher%20Education.pdf"> source 
                    </a>
                  </Typography>

          <div style={{ height: '200px' }} />

          </Hidden>
          <Typography component="h1" variant="h6"align="center" color="primary" gutterBottom>
            Want to learn more? <a href="/#recommendedReading"> Check out our recommended reading section. </a>
          </Typography>

          </MuiThemeProvider>
          
        
       
        </div>
        </div>



  );
}

Problem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Problem);