import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';

import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Header from '../sections/header.js';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider, createMuiTheme }from '@material-ui/core/styles';
import { Parallax, Background } from 'react-parallax';
import Divider from '@material-ui/core/Divider';


import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';
import deepBlue from '@material-ui/core/colors/blue';
const styles = theme => ({
  volunteerSection:{
    backgroundColor: '#191F35',//'#10171e',
    minHeight:800,
  },
  volunteerContent:{
    padding: `${theme.spacing.unit * 4}px  ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      padding: `${theme.spacing.unit * 8}px  ${theme.spacing.unit * 4}px ${theme.spacing.unit * 6}px ${theme.spacing.unit * 4}px`,
    },
    
  },
  volunteerItem:{
    padding: `${theme.spacing.unit * 2}px  ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    width:300,
    border: '2px solid ' + deepBlue[500],
    backgroundColor: '#191F35',//'#10171e',
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },

  },
  blueAvatar: {
    margin: 22,
    color: '#fff',
    backgroundColor: deepBlue[500],
    
    [theme.breakpoints.down(1100 + theme.spacing.unit * 3 * 2)]: {

      display:'none',
    },
  },


  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },


});



const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#ff4400',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
});

const volunteerList = [
  {
    id: '1',
    title: 'Run For Office',
    description:'Obv Run for office',
    
  },
  {
    id: '2',
    title: 'Stay In Touch',
    description:'Discord: https://discord.gg/vDrJ3hp',
    
  },
  {
    id: '3',
    title: 'Donate Time',
    description:'Donating time is also very helpful',
    
  },
 
 {
    id: '4',
    title: 'Donate Expertise',
    description:'We are going to need alot of expertise',
    
  },
 {
    id: '5',
    title: 'Donate Money',
    description:'At the end of the day, this is what wins elections.',
    
  },
 
 


];



function Volunteer(props) {
  const { classes } = props;

  return (
    <div className={classes.volunteerSection}>
        <div className={classNames(classes.layout,classes.volunteerContent)}>

       
          <MuiThemeProvider theme={theme} >
          <Typography component="h1" variant="h3"align="center" color="primary" gutterBottom>
            How To Help
          </Typography>
          
          <div style={{ height: '30px' }} />

          


        
        
          <Grid container className={classes.volunteerGrid} spacing={40} justify="space-evenly"  alignItems="center"  direction="column">
            
                {volunteerList.map(card => (

                <Grid item className={classes.volunteerGridItem} >

                <Grid container  direction="row">
          
                 <Grid item>
           
                <Avatar className={classes.blueAvatar}>{card.id}</Avatar>
                 </Grid>
                  <Grid item>
                <ExpansionPanel className={classes.volunteerItem}>

                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{color:'white'}}/>}>

                    <Typography variant="h6" component="h1" color="primary">
                    {card.title}
                  </Typography>
                    
                  
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography color="primary">
                       {card.description}
                    </Typography>
                  </ExpansionPanelDetails>
                  </ExpansionPanel>
         </Grid></Grid>
                </Grid>
             


               ))}
          
       
        </Grid>
        </MuiThemeProvider>
        </div>
        </div>



  );
}

Volunteer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Volunteer);