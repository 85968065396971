import React, { Component,PropTypes } from 'react';

import Button from '@material-ui/core/Button';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme }from '@material-ui/core/styles';
import { HashLink as Link } from 'react-router-hash-link';

import deepBlue from '@material-ui/core/colors/blue';
import deepPurple from '@material-ui/core/colors/purple';

const styles = theme => ({
  heroBanner: {
    
  },

  heroCard:{
    backgroundColor: '#10171e',//#191F35',
    border: '2px solid ' + deepBlue[300],
    height:'auto',
    width:280,
    marginLeft:'auto',
    marginRight:'auto',
    marginTop:'15%',
  
    background: '#10171e',
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      height:'auto',
      width:800,
    },
  },


  heroCardContent: {

    margin: '0 auto',
    padding: `${theme.spacing.unit * 4}px  ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      padding: `${theme.spacing.unit * 8}px  ${theme.spacing.unit * 4}px ${theme.spacing.unit * 6}px ${theme.spacing.unit * 4}px`,
    },

  },
  heroCTA: {
    marginTop: theme.spacing.unit * 1,
    display:'none',
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 20,
    },
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
    
  },
  heroGridItem:{
    margin: theme.spacing.unit * 1,
    padding: 5,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      margin: theme.spacing.unit * 2,
    },

  },
  heroCardGrid: {

    padding: `${theme.spacing.unit * 4}px 0`,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      padding: `${theme.spacing.unit * 8}px 0`,
    },
  
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
    highlight:{
    color: deepPurple[300],
    display: 'inline',
  },
  highlightAlt:{
    color: deepBlue[300],
    display: 'inline',
  },


});
const theme = createMuiTheme({
  palette: {

    primary: {
      // light: will be calculated from palette.primary.main,
      main: deepBlue[500],
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: deepPurple[300],
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
});
const theme2 = createMuiTheme({
  palette: {

    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: deepPurple[300],
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
});

function BannerCTA(props) {

      const { classes } = props;
      
                        
      return (
     
  

        <div classname={classes.heroBanner}>

        <Grid container spacing={40} className={classes.heroCardGrid} justify="center">
        <Grid  xs={12} md={6}>
        <Card className={classes.heroCard}>
      
          <div className={classes.heroCardContent}>

            <MuiThemeProvider theme={theme2} >
    
            <Typography component="h1" variant="h4" align="center" color="primary" gutterBottom>
              Would you run for office in 2020?
            </Typography>
             </MuiThemeProvider >
             <MuiThemeProvider theme={theme2} >
            <Typography component="h2" variant="h6"  align="center" color="primary" paragraph>
              We are seeking passionate <div className={classes.highlight}> <b> young people </b></div> willing to take on the establishment in key districts.
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={16} justify="center">
                <Grid item>
                  <Button variant="contained" color="primary" component={Link} to="#solution">
                    Learn More
                  </Button>
                </Grid>
           
              </Grid>
            </div>
            </MuiThemeProvider >
          </div>
        </Card>
        </Grid>
        </Grid>
        </div>
            



     
      )
   
}

export default withStyles(styles)(BannerCTA);
