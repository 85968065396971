import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';

import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Header from '../sections/header.js';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider, createMuiTheme }from '@material-ui/core/styles';
import { Parallax, Background } from 'react-parallax';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';


import deepBlue from '@material-ui/core/colors/blue';
import deepPurple from '@material-ui/core/colors/purple';

const styles = theme => ({
  pitchSection:{
    backgroundColor: '#191F35',
    minHeight:800,
  },
  pitchContent:{
    padding: `${theme.spacing.unit * 4}px  ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      padding: `${theme.spacing.unit * 8}px  ${theme.spacing.unit * 4}px ${theme.spacing.unit * 6}px ${theme.spacing.unit * 4}px`,
    },
    
  },
  pitchContentItem:{
  
    backgroundColor: '#191F35',
    marginBottom: 30,
    padding: '28px 28px 28px 28px'

  },
  pitchHeroContentItem:{
    border: '2px solid ' + deepBlue[500],
    backgroundColor: '#191F35',
    marginBottom: 30,
    padding: '28px 28px 28px 28px'

  },
  highlight:{
    color: deepPurple[300],
    display: 'inline',
  },
  highlightAlt:{
    color: deepBlue[300],
    display: 'inline',
  },

  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  layoutCompact: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(700 + theme.spacing.unit * 3 * 2)]: {
      width: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  layoutExtraCompact: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },


});



const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light:  deepBlue[300],
      main: deepPurple[300],
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    typography: {
    useNextVariants: true,
  },
    // error: will use the default color
  },
});





function Pitch(props) {
  const { classes } = props;

  return (
    <div className={classes.pitchSection}>
        <div className={classNames(classes.layoutCompact,classes.pitchContent)}>


          <MuiThemeProvider theme={theme} >
   
          
          <div style={{ height: '60px' }} />


         
            <Typography component="h3" variant="h5"align="center" color="primary">
            In 2020, millennials will be the largest voting block in the electorate.  <a href="http://www.pewresearch.org/fact-tank/2018/04/03/millennials-approach-baby-boomers-as-largest-generation-in-u-s-electorate/"> We have the numbers. </ a>
            </Typography>
         
            
         
             <div style={{ height: '100px' }} />
       

          <Paper className={classNames(classes.layoutCompact,classes.pitchHeroContentItem)}>
            
              <Typography component="h1" variant="h5" align="left" color="secondary" gutterBottom>
                OUR MISSION:
              </Typography>
              <Typography component="h3" variant="h4"align="left" color="primary" p >
                Elect as many millennials to office as possible. 
              </Typography>
          </Paper>

          <div style={{ height: '100px' }} />

         

   
            <Typography component="h4" variant="h6"align="center" color="primary" p >
            To do this, we are building a network of passionate individuals dedicated to empowering millennial candidates. It won’t be an easy battle, but we are willing to provide funding, training, and plain old-fashioned emotional support to those willing to run.
            </Typography>
     
            
         
            <Typography component="h3" variant="h5"align="center" color="secondary" p >
            <a href="/join">
            We are actively seeking passionate and talented individuals willing to take on the establishment in key districts.  
            </a>
            </Typography>
   
         
          
   
      
            <div style={{ height: '30px' }} />
            <Typography component="h4" variant="h6"align="center" color="primary" p >
              If you don’t think you would run, but would like to help out, <a href='/join'> we are looking to expand our network of dedicated individuals</a>. There are many ways to help, from volunteering, to simply providing emotional support for candidates during the early stages of their campaign.
            </Typography>
        

   
            

    

  

          

        </MuiThemeProvider>
        

        </div>
        </div>



  );
}

Pitch.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Pitch);