import React from 'react';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid,Text } from 'recharts';
import { withStyles } from '@material-ui/core/styles';
import deepPurple from '@material-ui/core/colors/purple';
import deepBlue from '@material-ui/core/colors/blue';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider, createMuiTheme }from '@material-ui/core/styles';
const data = [
  {'years from start':29, 'boomer': 4.0, 'gen-x': 0.0, 'millennial': 0.0, 'silent': 1.0},
   {'years from start':30, 'boomer': 4.0, 'gen-x': 3.0, 'millennial': 0.0, 'silent': 1.0},
   {'years from start':31, 'boomer': 10.0, 'gen-x': 3.0, 'millennial': 0.0, 'silent': 1.0},
   {'years from start':32, 'boomer': 10.0, 'gen-x': 6.0, 'millennial': 0.0, 'silent': 1.0},
   {'years from start':33, 'boomer': 18.0, 'gen-x': 6.0, 'millennial': 0.0, 'silent': 8.0},
   {'years from start':34, 'boomer': 18.0, 'gen-x': 7.0, 'millennial': 6.0, 'silent': 8.0},
   {'years from start':35, 'boomer': 38.0, 'gen-x': 7.0, 'millennial': null, 'silent': 14.0},
   // {'years from start':36, 'boomer': 38.0, 'gen-x': 11.0, 'millennial': null, 'silent': 14.0},
   // {'years from start':37, 'boomer': 56.0, 'gen-x': 12.0, 'millennial': null, 'silent': 30.0},
   // {'years from start':38, 'boomer': 56.0, 'gen-x': 18.0, 'millennial': null, 'silent': 31.0},
   // {'years from start':39, 'boomer': 63.0, 'gen-x': 19.0, 'millennial': null, 'silent': 58.0},

];
const data2= [

{'millennial': 0.0, 'boomer': 0.0, 'years from start': 26},
{'millennial': 0.0, 'boomer': 0.0, 'years from start': 27},
{'millennial': 0.0, 'boomer': 0.9, 'years from start': 28},
{'millennial': 0.0, 'boomer': 0.9, 'years from start': 29},
{'millennial': 0.0, 'boomer': 2.2, 'years from start': 30},
{'millennial': 0.0, 'boomer': 2.3, 'years from start': 31},
{'millennial': 1.4, 'boomer': 4.1, 'years from start': 32},
{'millennial': 1.4, 'boomer': 4.1, 'years from start': 33},
{'millennial': null, 'boomer': 8.5, 'years from start': 34},


];

const styles = theme => ({
  graphCard: {
   
    //border: '2px solid ' + deepBlue[500],
  


  },
  chartTitle:{
    paddingBottom:10,



  },
  wrapper:{
    paddingLeft:20,
    paddingTop:10,
     color: 'white',
    backgroundColor: '#10171e',//#191F35',
    //marginLeft:-20,
    marginRight:60,
  },

  xAxisLabel:{
    textAlign:'center',
    marginTop:-10
  },
    yAxisLabel:{
    left:10,
    transform: [{ rotate: '90deg'}],
  }


});

const theme2 = createMuiTheme({
  palette: {

    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: deepPurple[300],
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
});



function SimpleLineChart(props) {
  
  const { classes } = props;
  return (

 
    <div className={classes.wrapper}>
    <MuiThemeProvider theme={theme2} >
    
    <Typography component="h3" variant="h6" align="left" color="primary" gutterBottom>
               % of Congressional Seats Held
            </Typography>
    </MuiThemeProvider>
      
    <ResponsiveContainer className={classes.graphContainer}  width={'99%'} height={320}>
      <LineChart data={data2} style={{color:'white'}}   margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <XAxis dataKey="years from start" >
       
       
        </XAxis>
        <YAxis />
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <Tooltip />
        <Legend verticalAlign="insideLeft" />
  
        <Line type="monotone" dataKey="boomer" stroke={deepBlue[300]} />
   
        <Line type="monotone" dataKey="millennial" stroke={deepPurple[300]} />


        
      </LineChart>

    </ResponsiveContainer>
      <p className={classes.xAxisLabel}>
        Years since start of generation 
      </p>
      
      </div>
 

  );
}
export default withStyles(styles)(SimpleLineChart);
