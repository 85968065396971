import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme }from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';




import Header from '../sections/header.js';
import Mission from '../sections/mission.js';
import Values from '../sections/values.js';
import Vision from '../sections/vision.js';
import Footer from '../sections/footer.js';


const styles = theme => ({


  spacer:{
    height:100,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      height:200,
    },
  },
  wrapper:{
    minHeight:800,
    backgroundColor: '#10171e',//'#191F35',
  }



});



const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#ff4400',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
  typography: {
      fontFamily: "'Montserrat', sans-serif",
      textTransform: "none",
    }
});






function Join(props) {
  const { classes } = props;
 
  return (
    <React.Fragment>

      <CssBaseline />
      <Header/>
      
      <main>

        

      {/* Banner */}
      <div className={classes.wrapper}>
     
    
            <Vision />

         
     
        </div>



 


      </main>
      
      <Footer />
    </React.Fragment>
  );
}

Join.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Join);