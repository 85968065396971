import React, { PureComponent } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine,
} from 'recharts';


import { withStyles } from '@material-ui/core/styles';
import deepPurple from '@material-ui/core/colors/purple';
import deepBlue from '@material-ui/core/colors/blue';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider, createMuiTheme }from '@material-ui/core/styles';

const data = [
  {
    name: '25-29', neg: -9, pos:0,
  },
  {
    name: '65-69', neg:0, pos: 28,
  },
  {
    name: '70-74',neg:0, pos: 25,
  },
 
];

const data2 = [
  {
    name: '', 'age 25-29': -9, 'age 65-69':28,'age 70-74':25
  },

 
];


const styles = theme => ({
  graphCard: {
    color: 'white',
    backgroundColor: '#10171e',//#191F35',
    //border: '2px solid ' + deepBlue[500],
  


  },
  chartTitle:{
    paddingBottom:10,



  },
  wrapper:{
    paddingLeft:20,
    paddingTop:10,
    color:'white',
    width:'auto'
  },

  xAxisLabel:{
    textAlign:'center',
    marginTop:-10
  },
    yAxisLabel:{
    left:10,
    transform: [{ rotate: '90deg'}],
  }


});

const theme2 = createMuiTheme({
  palette: {

    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: deepPurple[300],
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
});


function IncomeBarChart(props)  {



    const { classes } = props;
    return (
      <div className={classes.wrapper}>
          <MuiThemeProvider theme={theme2} >
    
              <Typography component="h3" variant="h6" align="left" color="primary" gutterBottom>
                     % chg in U.S. Household Disposable Income,1979-2010
                  </Typography>
          </MuiThemeProvider>
      <BarChart
        width={props.width}
        height={300}
        data={data2}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <ReferenceLine y={0} stroke="#FFF" />
        <Bar dataKey='age 25-29'   fill={deepPurple[300]} />
        <Bar dataKey='age 65-69'  fill={deepBlue[300]} />
        <Bar dataKey='age 70-74'   fill={deepBlue[500]} />
      </BarChart>
      </div>
    );
  
}
export default withStyles(styles)(IncomeBarChart);
