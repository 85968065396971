import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme }from '@material-ui/core/styles';
import { Parallax, Background } from 'react-parallax';
import Hidden from '@material-ui/core/Hidden';

import WebFont from 'webfontloader';

import Header from '../sections/header.js';
import Footer from '../sections/footer.js';
import Mission from '../sections/mission.js';
import Pitch from '../sections/pitch.js';
import Problem from '../sections/problem.js';
import Volunteer from '../sections/volunteer.js';
import RecomendedReading from '../sections/recomended_reading.js';
import Districts from '../sections/districts.js';
import Contact from '../sections/contact.js';
import BannerCTA from '../models/banner_cta.js';


const styles = theme => ({


  spacer:{
    height:100,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      height:200,
    },
  },
  imageBanner:{
    background: 'black'
  },
  backSplash:{
    minHeight:500,
    [theme.breakpoints.up('sm')]: {
      minHeight:700,
    },
   
    //minHeight:720
    
  },


});



const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#ff4400',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
  typography: {
      fontFamily: "'Montserrat', sans-serif",
      textTransform: "none",
    }
});






function Home(props) {
  const { classes } = props;
 
  return (
    <React.Fragment>

      <CssBaseline />
      <Header/>
      
      <main>

        

      {/* Banner */}
      <div className={classes.imageBanner}>
        <Parallax
            className={classes.backSplash}
            bgImage={require('../../images/backsplash.jpg')}

            strength={-400}
             
            
        >
      

        

            
      
            
        </Parallax>
        </div>

      <div id= "problem">
     
        <Problem />
       
      </div>
 


      

      <div id= "districts">
        <Districts />
      </div>


      
      <div id= "recommendedReading">
        <RecomendedReading />
      </div>



 


      </main>
      
      <Footer />
    </React.Fragment>
  );
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);