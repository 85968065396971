import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme }from '@material-ui/core/styles';
import deepBlue from '@material-ui/core/colors/blue';
import deepPurple from '@material-ui/core/colors/purple';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
const styles = theme => ({

  heroUnit: {
    backgroundColor: '#191F35',

  },

  heroContent: {
    maxWidth: 800,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 4}px  ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      padding: `${theme.spacing.unit * 8}px  ${theme.spacing.unit * 4}px ${theme.spacing.unit * 6}px ${theme.spacing.unit * 4}px`,
    },
    
  },

  heroCTA: {
    marginTop: theme.spacing.unit * 1,
    display:'none',
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 20,
    },
  },
    pitchContentItem:{
  
    backgroundColor: '#191F35',
    marginBottom: 30,
    padding: '28px 28px 28px 28px'

  },
  pitchHeroContentItem:{
    border: '2px solid ' + deepBlue[500],
    backgroundColor: '#191F35',
    marginBottom: 30,
    padding: '28px 28px 28px 28px'

  },

});
const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light:  deepBlue[300],
      main: deepPurple[300],
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    typography: {
    useNextVariants: true,
  },
    // error: will use the default color
  },
});

class Mission extends React.Component {
  constructor(props) {
        super(props);

    }


  render() {
    const { classes } = this.props;

    return (


        <div className={classes.heroUnit}>
        <MuiThemeProvider theme={theme} >
          <div style={{ height: '100px' }} />
          <Grid container className={classes.heroGrid} justify="center" align="center">
          <Grid item className={classes.heroGridItem}>

               
              <Typography component="h1" variant="h5" align="left" color="secondary" gutterBottom>
                <b> OUR MISSION: </b>
              </Typography>
              <Typography component="h3" variant="h4"align="left" color="primary" p >
                We work to get young progressive candidates elected to office.
              </Typography>
               
  
          </Grid>

          <Grid item className={classes.heroGridItem}>
          <Typography component="h1" variant="h5" align="left" color="secondary" gutterBottom>
                OUR VISION:
          </Typography>
          
          <div style={{ height: '30px' }} />

          <Typography variant="h4" align="center" color="primary" gutterBottom>
            Balance Generational Representation
          </Typography>
         
          <Typography  variant="h6" align="center" color="primary" gutterBottom>      
              Young people are one of the least represented cohorts in American politics, and we are working to change that.
          </Typography>


          <div style={{ height: '60px' }} />


          <Typography variant="h4" align="center" color="primary" gutterBottom>
            Promote Ideas For the Future 
          </Typography>
          <Typography  variant="h6" align="center" color="primary" gutterBottom>      
              
             We support progressive policies that help build a better future for young working class people.

          </Typography>


          <div style={{ height: '60px' }} />
          

          <Typography variant="h4" align="center" color="primary" gutterBottom>
            Increase Political Engagement of Young People
          </Typography>
          <Typography  variant="h6" align="center" color="primary" gutterBottom>      
              
             We support young candidates that that talk about issues important to young people, to increase voter registration and participation from both Millennials and Generation Z.

          </Typography>
          </Grid>
        </Grid>
        </MuiThemeProvider>
        </div>


    );
  }
}

Mission.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Mission);