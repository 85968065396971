import React, { Component,PropTypes } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';


import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme }from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import classNames from 'classnames';

import deepBlue from '@material-ui/core/colors/blue';
import deepPurple from '@material-ui/core/colors/purple';

const styles = theme => ({

  root: {
    //background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    background:'white',
    //border: '5px solid ',
    //borderImage: 'linear-gradient(to left, #00C853, #B2FF59)',
    
    //borderColor: 'linear-gradient(45deg, '+deepBlue[300]+'30%, '+deepBlue[700]+' 90%)',
    //color: 'white',

  },

  formSection:{
    backgroundColor: '#10171e',//'#191F35',
    minHeight:800,
    paddingTop:100,
    paddingBottom:100,

  },
  formControl:
  {
    color: 'white'
  },
  checked:{
    color: 'white'
  },
    checkLabel:{
    color: 'white'
  },

  paperWrap:{



  background:  'linear-gradient(to right, #9cecfb, #65c7f7, #0052d4)',
  padding: '3px 3px 3px 3px',
  maxWidth:800,
  display:'flex',


    padding: 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      padding: 3,
    },

  },
  form:{
    width:'%100'
  },

  formWrapper: {
    paddingLeft:'10%',
    paddingRight:'10%',

  },
  formGrid:{
    display: 'block'
  },
  formButton:{
    background: 'white',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    backgroundColor: '#10171e',//'#191F35',,

    

    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {

      padding: theme.spacing.unit * 3,
    },
  },
  paperOverload:{

  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },



});
const theme = createMuiTheme({
  palette: {
    textPrimary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#ff4400',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
});

const recaptchaRef = React.createRef();

 class Form extends React.Component {
    constructor(props) {
        super(props);



        this.state = {
            email: '',
            name: '',
            message:'',
            recaptcha:'',
            cdtime:false,
            cdmoney:false,
            cdexpertise:false,
            cdsupport:false,
        
            cstayintouch:true,
            crun: false,
            submitted: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    handleCheckChange = name => event => {
        this.setState({ [name]: event.target.checked });
      };


    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { email, name, message,crun,cdtime,cdmoney,cdexpertise,cdsupport,cstayintouch} = this.state;
        // const { dispatch } = this.props;
        
            // dispatch(userActions.login(username, password));
        
        const recaptchaValue = recaptchaRef.current.getValue();
        if (recaptchaValue){
          console.log(recaptchaValue);
          console.log(data);

          const data = {
              name:name,
              reply_to:email,
              message:message,
              recaptcha:recaptchaValue,

              crun:crun,
              cdtime:cdtime,
              cdmoney:cdmoney,
              cdexpertise:cdexpertise,
              cdsupport:cdsupport,
              cstayintouch:cstayintouch
            };

          fetch('https://zo8gvdzpfj.execute-api.us-east-1.amazonaws.com/dev/static-site-mailer', {
            method: 'POST',
            mode: 'no-cors',
            body: JSON.stringify(data),
        });

        }
        
        
    }

    render() {
        const { loggingIn } = this.props;
        const { email, name,message, submitted,cdtime,cdmoney,cdexpertise,cdsupport,cstayintouch,crun} = this.state;
        const { classes, children, className, ...other } = this.props;

        if (submitted){
          return (

            <div className={classes.formSection}>
        <div className={classes.formWrapper}>
        <Grid container spacing={24} justify="center">
        <Grid item >
        <form name={classes.form}  onSubmit={this.handleSubmit}>
        <Paper className={classNames(classes.paperWrap, className)}>
        <Paper className={classNames(classes.paper, className)}>

        <MuiThemeProvider theme={theme} >
      <Typography variant="h4" color="primary" gutterBottom>
        Thanks! We will be in touch!
      </Typography>
      
      </MuiThemeProvider>
      </Paper>
        </Paper>
          
        </form>
        <p id="js-form-response"></p>

        </Grid>
        </Grid>
        </div>
        </div>
        )
        }
     
        
        return (




        <div className={classes.formSection}>
        <div className={classes.formWrapper}>
        <Grid container spacing={24} justify="center">
        <Grid item >
        <form name={classes.form}  onSubmit={this.handleSubmit}>
        <Paper className={classNames(classes.paperWrap, className)}>
        <Paper className={classNames(classes.paper, className)}>

        <MuiThemeProvider theme={theme} >
      <Typography variant="h4" color="primary" gutterBottom>
        Join
      </Typography>
      <Grid container spacing={24}>
        <Grid item xs={12} sm={6}>
       
          <TextField
            className={classNames(classes.root, className)}
            required
            id="name"
            name="name"
            label="Name"
            value={name} 
            onChange={this.handleChange} 
            fullWidth
            autoComplete="fname"
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            className={classNames(classes.root, className)}
            required
            id="email"
            name="email"
            label="Email"
            value={email} 
            onChange={this.handleChange} 
            fullWidth
            autoComplete="femail"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classNames(classes.root, className)}
            multiline
            rowsMax="4"
            id="message"
            name="message"
            label="message"
            value={message} 
            onChange={this.handleChange} 
            fullWidth
           
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset" className={classes.formControl}>

            <FormLabel component="legend">
              <Typography variant="h6" color="primary" gutterBottom>
              What are you interested in?
            </Typography>
            </FormLabel>
            <FormGroup>

              <FormControlLabel
                classes={{label: classes.checked,}}
                control={
                  <Checkbox checked={cstayintouch} onChange={this.handleCheckChange('cstayintouch')}  value="cstayintouch"  classes={{root: classes.checked, checked: classes.checked,}}/>
                }
                label="Stay In Touch"
              />
              <FormControlLabel

                classes={{label: classes.checked,}}
            
                control={
                  <Checkbox 
                    checked={crun} 
                    onChange={this.handleCheckChange('crun')}  
                    value="crun" 
                    classes={{root: classes.checked, checked: classes.checked,}}
                  />
                }
                label="Run For Office"
               
              />
              
              <FormControlLabel
                classes={{label: classes.checked,}}
                control={
                  <Checkbox checked={cdsupport} onChange={this.handleCheckChange('cdsupport')}  value="cdsupport"  classes={{root: classes.checked, checked: classes.checked,}}/>
                }
                label="Support Others"
              />

      
            </FormGroup>
       
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <ReCAPTCHA
            name="recaptcha"
            id="recaptcha"
            ref={recaptchaRef}
            sitekey="6LcCcpYUAAAAAGx0PfelXFNNxulBm9A9WiH3oRyD"
            onChange={this.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button 
          className={classNames(classes.formButton, className)}
          type="submit">Submit
          </Button>
        </Grid>
      </Grid>
      </ MuiThemeProvider >
      </Paper>
        </Paper>
          
        </form>
        <p id="js-form-response"></p>

        </Grid>
        </Grid>
        </div>
        </div>
       
        );
    }
}




export default withStyles(styles)(Form);
