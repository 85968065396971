import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';

import { Link } from 'react-router-dom';


// import { MuiThemeProvider, createMuiTheme }from '@material-ui/core/styles';

// import deepBlue from '@material-ui/core/colors/blue';
// import deepPurple from '@material-ui/core/colors/purple';

const styles = theme => ({
  card: {
    //maxWidth: 400,
    margin: '20px 20px 20px 20px',
    //margin: '60px 40px 60px 0',
    //padding:'0px 0px 10px 0px',
    //height:400,
    width:'auto',
    //padding: `${theme.spacing.unit * 8}px  ${theme.spacing.unit * 4}px ${theme.spacing.unit * 6}px ${theme.spacing.unit * 4}px`,
    // border: '2px solid ' +deepBlue[500],
    // background: '#10171e',
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      
      width:400,
    },
  },
  cardHeader:{
    minHeight:180
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9


  },
  actions: {
    display: 'flex',
  },

});


class MediaCard extends React.Component {
  constructor(props) {
        super(props);

    }
  state = { expanded: false };
 


 handleClick = () => {
    console.log("testing 1.2.3");

    window.open(this.props.link, "_blank");
  };

  




  render() {
    const { classes } = this.props;
    

    return (
      <Card className={classes.card}>
    
        <CardActionArea  onClick={event => { this.handleClick()}}>
        <CardHeader
    
          className={classes.cardHeader}
          title={this.props.title}
          subheader={this.props.date}
        />
        

        <CardMedia
          className={classes.media}
          image={this.props.image}
          title="Paella dish"
        />
        </CardActionArea>
     

        
      </Card>
    );
  }
}

MediaCard.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(MediaCard);