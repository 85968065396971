import React, { Component,PropTypes } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme }from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import { HashLink} from 'react-router-hash-link';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'black',//'#10171e',
    height: '70px',



  },
  logo:{
    maxHeight:50
  },

  title: {
    flexGrow:1,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  titleMenu:{
    display:'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  subAppBar:{
    display:'block',
    position: 'relative',
    backgroundColor: 'black',//#10171e',
 
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

});
const theme = createMuiTheme({
  palette: {
    textPrimary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#ff4400',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
});


function Header(props) {

      const { classes } = props;
      
                        
      return (
        <div className={classes.wrapper}>
     
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
          <MuiThemeProvider theme={theme} >
            
            <Typography variant="h6" color="white" noWrap className={classes.title}>
            <Button color="primary" component={Link} to="/">

              <div className={classes.logo}>
              <img src={require('../../images/logo.png')} height={50} />
              </div>
            </Button> 
            </Typography>
            

            <div className={classes.titleMenu}>
              <Grid container spacing={6} justify="space-around" alignContent="center">

              
              <Grid item><Button color="primary"  component={Link} to="/mission" >Mission</Button></Grid>
           
              <Grid item><Button color="primary" component={HashLink} to="/contact" >Contact</Button></Grid>
              <Grid item><Button color="secondary" target="_blank" href="https://secure.actblue.com/donate/youngpac-1">Donate</Button></Grid>
          
            </Grid>
            </div>
            </MuiThemeProvider>
          </Toolbar>
        </AppBar>

     
        <AppBar position="static" className={classes.subAppBar}>
          <Toolbar>
          <MuiThemeProvider theme={theme} >
          <Grid container spacing={6} justify="space-evenly" alignContent="center">

            
              <Grid item><Button color="primary"  component={Link} to="/mission" >Mission</Button></Grid>
              <Grid item><Button color="primary" component={HashLink} to="/contact" >Contact</Button></Grid>
              <Grid item><Button color="secondary" target="_blank" href="https://secure.actblue.com/donate/youngpac-1">Donate</Button></Grid>
         
          </Grid>
              
         
            </MuiThemeProvider>
          </Toolbar>
        </AppBar>

        </div>
            



     
      )
   
}

export default withStyles(styles)(Header);
