import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CardActionArea from '@material-ui/core/CardActionArea';


import { MuiThemeProvider, createMuiTheme }from '@material-ui/core/styles';
import { Parallax, Background } from 'react-parallax';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import CustomizedSelects from '../models/customized_selects.js';
import InputBase from '@material-ui/core/InputBase';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import deepBlue from '@material-ui/core/colors/blue';
import deepPurple from '@material-ui/core/colors/purple';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';

import ca12 from '../../images/candidates/CA12.jpg';
import ca18 from '../../images/candidates/CA18.jpg';
import ca19 from '../../images/candidates/CA19.jpg';
import ca32 from '../../images/candidates/CA32.jpg';
import co27 from '../../images/candidates/CO27.jpg';
import sc6 from '../../images/candidates/SC6.jpg';
import tn5 from '../../images/candidates/TN5.jpg';
import tx10 from '../../images/candidates/TX10.jpg';
import tx22 from '../../images/candidates/TX22.jpg';
import tx24 from '../../images/candidates/TX24.jpg';
import tx35 from '../../images/candidates/TX35.jpg';


const styles = theme => ({

  districtSection:{

    backgroundColor: '#10171e',//#191F35',
    minHeight:800,
    paddingLeft:20,
    paddingRight:20,
    paddingBottom:60,


  },

  districtIntroText:{

    
    padding: '0x 8px 18px 0px',
    
    marginBottom:60,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      padding: '28px 28px 28px 28px',
      border: '2px solid ' +  deepBlue[500],
      borderRadius: '8px',
    },

    

  },
  districtIntroTextList:{

    

  },

  districtFilterControls:{

    paddingBottom:40,
    marginBottom:20,
    marginTop:20,
    borderBottom: '2px solid '  +  deepBlue[500],


  },

  districtCard: {
    display: 'flex',
    flexGrow:1,

  },

  districtGrid: {
    //padding: `${theme.spacing.unit * 8}px 20px`,
  },
  card: {
    height: 120,
    minWidth:320,
    backgroundColor: '#10171e',//#191F35',
    border: '1px solid ' +  deepBlue[300],
    width: 'auto',

  },
  districtCardMedia: {
   
     height: '100%',
     width: 120 *(225/275),
   

  },
  districtCardContent: {
   
    //paddingLeft:20,

    
  },
  filterSelectors: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  highlight:{
    color: deepPurple[300],
    display: 'inline',
  },
  highlightAlt:{
    color: deepBlue[300],
    display: 'inline',
  },

  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  layoutCompact: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 0,
    marginRight: theme.spacing.unit * 0,
    [theme.breakpoints.up(800 + theme.spacing.unit * 3 * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
});



const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffffff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#ff4400',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
});

const districts = [
  {
    district: 'California-12',
    state:'San Francisco, CA',
    party:'Dem',
    holder: 'Nancy Pelosi',
    holderImg: ca12,
    description:'',
    link:'http://bit.ly/AgathaForCongress',
    candidate: {
      name: 'Agatha Bacelar',
      twitter: 'AgathaBacelar',
      info:'http://bit.ly/AgathaForCongress',
    }
  },
  {
    district: 'California-18',
    state:'San Francisco, CA',
    party:'Dem',
    holder: 'Anna Eshoo',
    holderImg:ca18,
    description:'',
    link:'https://en.wikipedia.org/wiki/California%27s_18th_congressional_district',
    candidate: null
  },
  {
    district: 'California-19',
    state:'San Jose, CA',
    party:'Dem',
    holder: 'Zoe Lofgren',
    holderImg:ca19,
    description:'',
    link:'https://en.wikipedia.org/wiki/California%27s_19th_congressional_district',
    candidate: null
  },
  {
    district: 'California-32',
    state:'Los Angeles, CA',
    party:'Dem',
    holder: 'Grace Napolitano',
    holderImg:ca32,
    description:'',
    link:'https://en.wikipedia.org/wiki/California%27s_32nd_congressional_district',
    candidate: null
  },

    {
    district: 'Colorado-7',
    state:'Denver, CO',
    party:'Dem',
    holder: 'Diana DeGette',
    holderImg:co27,
    description:'',
    link:'https://en.wikipedia.org/wiki/Colorado%27s_27th_congressional_district',
    candidate: null
  },
      {
    district: 'South Carolina-6',
    state:'Charleston, SC',
    party:'Dem',
    holder: 'Jim Clyburn',
    holderImg:sc6,
    description:'',
    link:'https://en.wikipedia.org/wiki/South_Carolina%27s_6th_congressional_district',
    candidate: null
  },
    {
    district: 'Tennessee-5',
    state:'Nashville, TN',
    party:'Dem',
    holder: 'Jim Cooper',
    holderImg:tn5,
    description:'',
    link:'https://en.wikipedia.org/wiki/Tennessee%27s_5th_congressional_district',
    candidate: null
  },
        {
    district: 'Texas-10',
    state:'Austin, TX',
    party:'Rep',
    holder: 'Michael McCaul',
    holderImg:tx10,
    description:'',
    link:'https://en.wikipedia.org/wiki/Texas%27s_10th_congressional_district',
    candidate: null
  },
      {
    district: 'Texas-22',
    state:'Houston, TX',
    party:'Rep',
    holder: 'Pete Olson',
    holderImg:tx22,
    description:'',
    link:'https://en.wikipedia.org/wiki/Texas%27s_22nd_congressional_district',
    candidate: null
  },
        {
    district: 'Texas-24',
    state:'Dallas, TX',
    party:'Rep',
    holder: 'Kenny Marchant',
    holderImg:tx24,
    description:'',
    link:'https://en.wikipedia.org/wiki/Texas%27s_22nd_congressional_district',
    candidate: null
  },
        {
    district: 'Texas-35',
    state:'Austin, TX',
    party:'Dem',
    holder: 'Lloyd Doggett',
    holderImg:tx35,
    description:'',
    link:'https://en.wikipedia.org/wiki/Texas%27s_22nd_congressional_district',
    candidate: null
  },


];

const states = [
{state:'California'},
{state:'Colorado'},
{state:'Tennessee'},
{state:'South Carolina'},
{state:'Texas'},
]

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);





class Districts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      stateFilter: null,
      filteredDistricts:districts,
      districts:districts,
      openDialog: false,
    };
    
  }


  handleChange = event => {
    this.setState({ stateFilter: event.target.value });
    console.log("TEST");
    this.setState({
    filteredDistricts: districts.filter((item) => {
      return item.district.indexOf(event.target.value)  !== -1
    
    })
  });
  };

handleClickOpen = () => {
    
  };

  handleClose = () => {
    this.setState({ open: false });
  };

render () { 
 
   const { classes } = this.props;
  return (

      <div className={classes.districtSection}>
      <div style={{ height: '60px' }} />

      <div className="section-title target-districts">
        <MuiThemeProvider theme={theme} >
          <Typography component="h1" variant="h3"align="center" color="primary" gutterBottom>
            Target Districts
          </Typography>

          
        </MuiThemeProvider>

      </div>



      <div className={classNames(classes.layoutCompact, classes.districtIntroText)}>


          <MuiThemeProvider theme={theme} >
          <Typography component="h4" variant="h6" align="center" color="primary" p >
              We believe the following districts have an extremely high chance of <div className={classes.highlight}>flipping millennial</div>, and we are actively seeking candidates to support in these areas.
          
          </Typography>
              <div className={classes.districtIntroTextList}>
              <Typography variant="subtitle1" align="left" color="primary" p >
              <ul >
                <li> High % of millennial population relative to older generations </li>
                <li> Attracting more millennials every day </li>
                <li> Rapidly increasing housing costs </li>
                <li> Seat currently held by a boomer </li>
                <li> We excluded districts that were traditionally Republican but flipped Democrat in 2018, to avoid placing unnecessary pressure on these districts. </li>
              </ul>
              </Typography>
              <Grid container spacing={16} justify="center">
                <Grid item>
                  <Button variant="contained" color="primary" component={Link} to="/contact">
                    Contact
                  </Button>
                </Grid>
           
              </Grid>
              </div>
             
 
    
             </MuiThemeProvider>
      </div>

      <div className={classNames(classes.layout, classes.districtFilterControls)}>
 
     
          <Grid container className={classes.filterSelectors} spacing={40} justify="space-evenly"  alignItems="center" >
          <FormControl className={classes.margin}>
          <InputLabel htmlFor="age-customized-native-simple" className={classes.bootstrapFormLabel}>
            State
          </InputLabel>
          <NativeSelect
            value={this.state.state}
            onChange={this.handleChange}
            input={<BootstrapInput name="age" id="age-customized-native-simple" />}
          >
            <option value=""> State</option>
            {states.map(v => (
            <option value={v.state}>{v.state}</option>
           
            ))}
          </NativeSelect>
        </FormControl>
        </Grid>
      
      </div>

  


      

         
        

        <div className={classNames(classes.layout, classes.districtCardGrid)}>
          {/* End hero unit */}
          <Grid container className={classes.districtGrid} spacing={40} justify="space-evenly"  alignItems="center" >
            {this.state.filteredDistricts.map(card => (


              
              
              <Grid item key={card.district}   >
                
                <Card className={classes.card}>
                <CardActionArea  onClick={this.handleClickOpen(card.link)}>
                <Grid item  container direction="row" spacing={2} >
                  <Grid item  zeroMinWidth >
             
                  <CardMedia className={classes.districtCardMedia} >
                  <img
                    src={card.holderImg}
                    alt="districts"
                    className={classes.districtCardMedia}

                  />
                  </CardMedia>
               
                  </Grid>
                  <Grid item zeroMinWidth >

                  <CardContent className={classes.districtCardContent}>
                  <MuiThemeProvider theme={theme} >
                    <Typography gutterBottom variant="h5" component="h2" color="primary">
                      {card.district}
                    </Typography>
                    <Typography color="primary">
                      {card.state}
                    </Typography>
                    <Typography color="primary">
                      {card.holder}
                    </Typography>
                    </MuiThemeProvider>
                  </CardContent>
                </Grid>
                </Grid>
                </CardActionArea>
              
                </Card>

              </Grid>
              

            ))}
          </Grid>
        </div>
        </div>
       
 
 



  );
}
}
Districts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Districts);